<template lang="pug">
  b-card.manager-identities-form.card-white.card-no-gutters.shadow(no-body)
    b-card-header.bg-transparent.border-0
      h5.m-0
        | {{ $t('activerecord.models.identity.one') }}
    b-form.form-wc.form-identity-form.mt-sm-3(
      @submit.stop.prevent='formSubmit'
      @input.stop.prevent='formChange'
      @reset.stop.prevent='formReset')
      b-card-body

        wc-forms-alerts(v-if='formShowErrors' :errors='form.errors.base')

        transition(name='fade')
          fieldset(v-if='formShow' :disabled='formDisable')
            b-form-group#form-identity-username(
              :label="$t('activerecord.attributes.identity.username')"
              label-for='identity-username'
              label-cols-md='2'
              label-align-md='right')
              b-form-input#identity-username.shadow-sm.is-required(
                name='username'
                type='text'
                autocomplete='username'
                :placeholder="$t('identity.placeholders.username')"
                :state='formValidationState($v.form.data.attributes.username)'
                :readonly="!isAdmin && form.data.attributes.identifiable_type_snake !== 'organization'"
                @focus.native='formChange'
                v-model='$v.form.data.attributes.username.$model'
                aria-describedby='identity-username-feedback'
                trim)
              wc-forms-if#identity-username-feedback(
                extra-class='ml-feedback'
                :attribute='$v.form.data.attributes.username'
                :remote="formRemoteInvalidFeedback('username')"
                :validators='{ required: {}, regExp: {}, minLength: {}, maxLength: {}, takenOrNotAllowed: {} }')

      b-card-footer.border-0.bg-white
        wc-forms-buttons#form-identity-buttons(:disabled='formDisable || $v.form.data.$invalid')

    loading(:active.sync='isLoading' :can-cancel-esc='true' :on-cancel='onCancel')
</template>

<script>
import WcFormsButtons from '@components/shared/forms/WcFormsButtons'
import apiIdentities from '@services/api/manager/identities'
import { Form } from '@common/form'
import { regExp } from '@common/form/validations'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'

export default {
  name: 'manager-identities-form',
  mixins: [Form],
  components: {
    WcFormsButtons,
  },
  computed: {
    isAdmin() {
      /* This $root is used because of a bug on server side. */
      return this.$root.$store.getters['auth/isAdmin']
    },
    identityUpdateQuery() {
      return this.action === 'update' ? { id: this.identity_id } : {}
    },
    apiParams() {
      return {
        get: [{ id: this.identity_id }, {}],
        update: [
          { id: this.identity_id },
          { [this.apiModel]: this.formSubmitData(this.$getDeep(this.form, 'data.attributes')) },
        ],
        uniqueUsername: [
          {},
          {
            ...this.identityUpdateQuery,
            term: this.$getDeep(this.form, 'data.attributes.username'),
          },
          { responseErrorRaw: true },
        ],
      }
    },
  },
  methods: {
    apiCallback(callback) {
      if (callback === 'submit-success') this.$emit('identity-changed')
    },
  },
  validations() {
    return {
      form: {
        data: {
          attributes: {
            username: {
              required,
              regExp: regExp(this.$wc.conf.regex.username_browser),
              minLength: minLength(this.$wc.conf.limits.min_username),
              maxLength: maxLength(this.$wc.conf.limits.max_username),
              takenOrNotAllowed: async () => {
                /* Validate only if. */
                if (
                  this.validateIf('username', ['required', 'regExp', 'minLength', 'maxLength'], {
                    if: 'invalid',
                  })
                )
                  return true

                /* Validate remote. */
                return await new Promise((resolve) => {
                  clearTimeout(this.form.timer.uniqueUsername)
                  this.form.timer.uniqueUsername = setTimeout(resolve, 600)
                }).then(() => {
                  if (this.apiRequest && this.apiRequest.source) this.apiRequest.source.cancel()
                  this.apiRequest = this.formRequest('uniqueUsername')
                  return this.apiRequest.promise // eslint-disable-line promise/no-nesting
                    .then(() => {
                      return true
                    })
                    .catch((error) => {
                      return error.response && error.response.status === 422 ? false : true
                    })
                })
              },
              remote: () => this.formRemoteValid('username'),
            },
          },
        },
      },
    }
  },
  data() {
    return {
      apiBase: apiIdentities,
      apiModel: 'identity',
      identity_id: this.$route.params.identity_id,
    }
  },
}
</script>
