<template lang="pug">
  b-button-group.wc-forms-buttons
    b-button.form-buttons-reset.text-white(
      type='reset'
      variant='secondary')
      i.fal.fa-fw.fa-redo
      |  {{ $t('shared.actions.reset') }}
    b-button.form-buttons-submit.text-white(
      type='submit'
      variant='primary'
      :disabled='disabled')
      i.fal.fa-fw.fa-check-circle
      |  {{ $t('shared.actions.save') }}
</template>

<script>
export default {
  name: 'wc-forms-buttons',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
